.checkout-page {

    .form-card {
        /* Set a fixed width for the card */
        background: rgba(255, 255, 255, 0.05);
        margin: 20px;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(10px);

        &:hover .form-card-content {
            transform: translateY(-20px);
        }
    }
}