.nav {
  .img-icon {
    width: 70px;
  }

  .sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .svg-container {
    height: 5vh;
    padding-bottom: 17px;
    .svg-img-logo {
      max-width: 75%;
      height: 8vh;

    }
  }
}