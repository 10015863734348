.home-page {
  color: white;
  height: 100vh;
  /* Set the height to 100vh to fill the entire viewport */
  overflow-y: auto;

  .parallax {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    .centered-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    @media (max-width: 600px) {
      .centered-content {
        height: auto;
        min-height: 100vh;
      }
    }

    .svg-container {
      width: 100%;
      // height: 50vh;
      margin-bottom: 10px;
      margin-top: -100px;

      .svg-img-logo {
        max-width: 100%;

      }

      
    }

    .square-animation {
      position: relative;
      display: inline-block;
      padding-right: 25px;
      padding-left: 25px;
    }
    
    .square-animation::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 10px;
      right: 10px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 4px solid transparent;
      background: linear-gradient(41deg, rgba(55,32,19,1) 7%, rgba(245,234,195,1) 50%, rgba(241,233,147,1) 80%);
      opacity: 0.5;
      animation: square-animation 1s ease-in-out forwards;
      box-sizing: border-box;
    }
    
    @keyframes square-animation {
      0% {
        width: 0;
        border-color: transparent;
      }
      25% {
        width: 25%;
        border-color: linear-gradient(41deg, rgba(55,32,19,1) 7%, rgba(245,234,195,1) 50%, rgba(241,233,147,1) 80%);
      }
      100% {
        width: 100%;
        border-color: linear-gradient(41deg, rgba(55,32,19,1) 7%, rgba(245,234,195,1) 50%, rgba(241,233,147,1) 80%); /* Use your desired border color */
      }
    
    }
    


    .img-logo {
      width: 15%;
      margin: 0 auto;
    }

    h1 {
      font-weight: bold;
      letter-spacing: 7px;

      @media screen and (max-width: 600px) {
        font-size: 36px; // Set font size for screens up to 600px wide
      }

      @media screen and (min-width: 601px) and (max-width: 960px) {
        font-size: 48px; // Set font size for screens between 601px and 960px wide
      }

      @media screen and (min-width: 961px) {
        font-size: 150px; // Set font size for screens wider than 960px
      }
    }

    .img-header {
      width: 100%;
    }

    .grid-container {
      // border: 5px solid #dd9756;
      position: relative;
      overflow: hidden;


      .button-grid-item {
        padding-bottom: 10px;
      }
    }

    @keyframes fillBorderAnimation {
      0% {
        border-width: 2px;
      }

      100% {
        border-width: 100%;
      }
    }

    
    .grid-container::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .products-section {
    display: flex;
    justify-content: center;

    .product-column {
      flex-basis: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      i {
        font-size: 36px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        text-align: center;
      }


    }
  }
 
}