.company-info {
  .form-card {
    background: rgb(60, 60, 67);
    color: #fff;
    position: relative;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    overflow: hidden;
  }

  .form-card-media {
    background: linear-gradient(31deg,
        rgba(12, 14, 21, 1) 3%,
        rgba(28, 33, 47, 1) 16%,
        rgba(55, 54, 59, 1) 66%,
        rgba(59, 58, 62, 1) 76%,
        rgba(93, 90, 89, 1) 97%); 
    // position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 40vh;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    margin: auto;
    /* position: absolute;
    bottom: 50px; */
    /* display: flex; */
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 16px;
    /* Adjust the margin as needed */
  }

  .form-column {
    flex-direction: column;
    padding: 0 8px;

    &:first-child {
      padding-right: 0;
    }

    // &:nth-child(2) {
    //   /* border-left: 1px solid white; */
    // }

    &:last-child {
      padding-left: 0;
    }

    &:last-child .form-control {
      &:first-child::after,
      &:last-child::after {
        display: none;
      }
    }

    .form-control {
      margin-top: 80px;

      &:not(:last-child)::after,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        width: 1px;
        height: 100%;
        background-color: rgb(91,91,96);
      }

      &:not(:last-child)::after {
        height: calc(100% - -10px);
        top: -40px;
      }

      &:last-child {
        padding-right: 0;

        &::after {
          height: calc(100% - -10px);
          top: -40px;
          /* Adjust this value to match your design */
        }
      }

      > label {
        color: white !important;
        padding-left: 30px;
      }

      > div input {
        color: white !important;
      }

      > div:before {
        border-bottom: 1px solid rgb(91,91,96);
      }
    }
  }
}
