.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    flex-grow: 1;
    /* Use flex-grow to fill the remaining vertical space */
    overflow-y: auto;
    /* Add overflow-y to enable scrolling when the content overflows */
    margin: auto;
    height: 100%;

    .contact-message {
        border: solid 1px white;
    }
    
    .img-logo {
        width: 15%;
        margin: 0 auto;

        @media screen and (max-width: 600px) {
            width: 25%;
        }

        @media screen and (min-width: 601px) and (max-width: 960px) {
            width: 35%;
        }

    }

    .form-card {
        position: relative;
        /* Set a fixed width for the card */
        background: rgba(255, 255, 255, 0.05);
        margin: 20px;
        box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(10px);
        margin: auto;
        padding: 25px;

        &:hover .form-card-content {
            transform: translateY(-20px);
            cursor: pointer;
        }

        .form-card-content {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 0.5s;
            width: 100%;
            /* Ensure the content is centered within the card */
            flex-wrap: wrap;
            text-align: center;
            /* Add this line to center the content horizontally */

            .email-link {
                cursor: pointer;
                transition: color 0.3s;
                text-decoration: underline;

                &:hover {
                    color: blue;
                }
            }
        }
    }
}