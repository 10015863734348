.services-form-container {
    // max-height: 500px; /* Adjust the height as per your requirement */
    overflow-y: auto;
    form {
      .MuiInputBase-root {
        color: #fff; // Set the font color to white
      }

      .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
        color: white !important;
      }

      > input {
        color: white;
      }
    }

    .add-button {
      margin-top: 30px;
      float: right;
    }
    div.services-form-input {
      color: white !important;

      > input {
        color: white !important;
      }
    }

    .delete-button-container {
      margin: auto;
    }
  }