.confirmation-page {
    text-align: center;
    color: white;
    padding-top: 25px;
    width: 100%;
    
    td {
      border-bottom: none;
    }
    .action-button {
        display: flex;
        justify-content: center;
        margin: auto;
        margin-top: 50px;
    
      }
}