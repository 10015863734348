
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

:root {
  --font-primary: 'Nunito', sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: none; /* Remove the background color */
  overflow: hidden; /* Prevent scrolling outside the page */
  padding-top: env(safe-area-inset-top);
  background: linear-gradient(31deg, rgba(12, 14, 21, 1) 3%, rgba(28, 33, 47, 1) 16%, rgba(55, 54, 59, 1) 66%, rgba(59, 58, 62, 1) 76%, rgba(93, 90, 89, 1) 97%);
}

#root {
  font-family: var(--font-primary);
  background: none; /* Remove the background color */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent scrolling outside the page */
}

.App {
  text-align: center;
  min-height: 100vh; /* Set the minimum height to fill the viewport */
  background: linear-gradient(31deg, rgba(12, 14, 21, 1) 3%, rgba(28, 33, 47, 1) 16%, rgba(55, 54, 59, 1) 66%, rgba(59, 58, 62, 1) 76%, rgba(93, 90, 89, 1) 97%);
  overflow-y: auto; /* Enable vertical scrolling when content exceeds viewport height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
