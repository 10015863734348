.service-form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  flex-grow: 1;
  /* Use flex-grow to fill the remaining vertical space */
  overflow-y: auto;
  /* Add overflow-y to enable scrolling when the content overflows */

  .stepper-container{
    margin: 10px;
  }
  .form-card {
    position: relative;
    /* Set a fixed width for the card */
    background: rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    margin: auto;

    &:hover .form-card-content {
      transform: translateY(-20px);
    }

    .form-card-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: 0.5s;
      width: 100%;
      /* Ensure the content is centered within the card */
      flex-wrap: wrap;
      text-align: center; /* Add this line to center the content horizontally */
    }
  }

  .form-stepper {
    width: 100%;
    margin-bottom: 16px;
  }

  .form-button {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .form-button>.MuiButton-root {
    background-color: rgb(221, 164, 102);
    color: #fff;
    width: 120px;
  }

  .button-grid {
    margin-bottom: 70px;
    }

  .action-button {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 50px;

  }

  .stepper-container {
    width: 80%;
    margin: auto;
    padding-bottom: 25px;
    padding-top: 25px;
    @media (max-width: 768px) {
      width: 80%; /* Adjust the width for smaller screens */
    }

    @media (max-width: 480px) {
      width: 90%; /* Adjust the width for even smaller screens */
    }

    @media (max-width: 375px) {
      width: 100%; /* Adjust the width for the smallest screens */
    }
  }

  @media (max-width: 768px) {

    /* Adjust the max-width value as needed */
    .form-card {
      width: 80%;
      /* Reduce the card's width for smaller screens */
    }
  }

  @media (max-width: 480px) {

    /* Adjust the max-width value as needed */
    .form-card {
      width: 90% !important;
      /* Set the card's width to 100% for even smaller screens */
      /* Add margin to provide some spacing */
    }
  }

  @media (max-width: 375px) {

    /* Adjust the max-width value as needed */
    .form-card {
      width: 100% !important;
      /* Set the card's width to 100% for even smaller screens */
      /* Add margin to provide some spacing */
    }
  }
}
