// CreditCardForm.module.scss

.credit-card-form {
  width: 100%;
  margin: 20px 0;

  .group {
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-flow: column;
    .credit-card {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    label {
      position: relative;
      color: white;
      font-weight: 300;
      // height: 40px;
      // line-height: 40px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px rgb(246, 246, 246);
      > span {
        width: 120px;
        text-align: right;
        margin-right: 10px;
      }
    }

    .field {
      background: transparent;
      font-weight: 300;
      color: #d9d9d9;
      outline: none;
      flex: 1;
      padding: 5px;
      cursor: text;

      &:focus {
        box-shadow: none;
      }
    }

    button {
      display: block;
      box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      border: 0;
      margin-top: 20px;
      font-size: 15px;
      font-weight: 400;
      width: 100%;
      height: 40px;
      line-height: 38px;
      outline: none;
      background: linear-gradient(41deg, rgba(55, 32, 19, 1) 7%, rgba(245, 234, 195, 1) 50%, rgba(241, 233, 147, 1) 80%);
      color: white;

      &:focus {
        background: linear-gradient(41deg, rgba(55, 32, 19, 1) 7%, rgba(245, 234, 195, 1) 50%, rgba(241, 233, 147, 1) 80%);
      }

      &:active {
        background: linear-gradient(41deg, rgba(55, 32, 19, 1) 7%, rgba(245, 234, 195, 1) 50%, rgba(241, 233, 147, 1) 80%);
      }
    }

      input {
      color: white;
      width: 100%;
      padding: 8px;
      border-radius: 4px;
      border: none;
    }

    @media (min-width: 480px) {
      padding: 10px;

      .credit-card-form {
        width: 480px;
        margin: 20px auto;
      }

      label > span {
        width: 160px;
        margin-right: 20px;
      }

      .field {
        padding: 8px;
      }
    }
  }
}
